import "./Input.scss";
import { useFormContext } from "react-hook-form";

export const Input = ({
  inputName,
  className,
  type,
  placeholder,
  error,
  validationSchema
}) => {
  const { register, errors } = useFormContext();

  return (


    <label className="label">
      <input
        {...register(inputName, validationSchema)}
        type={type}
        placeholder={placeholder}
        className={className}
        name={inputName}
      />

      {error && <p className="error">{error.message}</p>}
    </label>
  );
};
