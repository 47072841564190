import React from 'react';
import './PackageSize.scss';

export const PackageSize = ({ isActive, isDisabled, register, icon, size, range, packageDescription }) => {
  return (
    <label>
      <div
        className={
          isDisabled
          ? 'disabled'
          : isActive
          ? 'activePackage'
          : 'packageSize__option'
      }
       
      >
        <div className="packageSize__option--image">{icon}</div>
        <div className="packageSize__option--text">
          <div className="packageSize__option--title">{packageDescription}</div>
          <div className="packageSize__option--weight">{range}</div>
        </div>
      </div>
      <input
        {...register('rozmiar_paczki', {
          required: true,
        })}
        type="radio"
        name="rozmiar_paczki"
        value={size}
        disabled={isDisabled}
      />
    </label>
  );
};
