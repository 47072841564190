
import "./StepNumber.scss"
import { useFormContext } from "react-hook-form";



export const StepNumber = ({number, titleText, descriptionText, mail, linename}) => {
    const { getValues, watch } = useFormContext();
    const values = getValues();

    const getBody = () => {
        return (
          "Pojawił się problem z przetworzeniem formularza. Przesyłam dane podane w formularzu serwisowym." +
          "%0D%0A" +
          "ZGŁASZAJĄCY   " +
          "%0D%0A" +
          "Imię i nazwisko: " +
          values.imie +
          " " +
          values.nazwisko +
          "%0D%0A" +
          "Nazwa firmy i NIP: " +
          values.nazwafirmy +
          values.nip +
          "%0D%0A" +
          "Adres: " +
          values.nadawcaulica +
          " " +
          values.nadawcanrdomu +
          "/" +
          values.nadawcanrlokalu +
          " " +
          values.nadawcakodpocztowy +
          " " +
          values.nadawcamiasto +
          "%0D%0A" +
          "Telefon: " +
          values.tel +
          "%0D%0A" +
          "Email: " +
          values.email +
          "%0D%0A" +
          "NARZĘDZIE" +
          "%0D%0A" +
          "Typ reklamacji: " +
          values.narzedzia[0].typzgloszenia +
          "%0D%0A" +
          "Model: " +
          values.narzedzia[0].modeltowaru +
          "%0D%0A" +
          "Nr seryjny: " +
          values.narzedzia[0].nrseryjny +
          "%0D%0A" +
          "Data zakupu: " +
          values.narzedzia[0].datazakupu +
          "%0D%0A" +
          "Akceptacja kosztów " +
          values.narzedzia[0].akceptkosztownogw +
          "%0D%0A" +
          "Opis usterki: " +
          values.narzedzia[0].opisusterki +
          "%0D%0A" +
          "ODBIÓR PRZESYŁKI" +
          "%0D%0A" +
          "Przewoźnik: " +
          values.typprzesylki +
          "%0D%0A" +
          "Rozmiar paczki: " +
          values.rozmiar_paczki +
          "%0D%0A" +
          "Dzień odbioru: " +
          values.dzien_odbioru +
          "%0D%0A" +
          "Godzina odbioru: " +
          values.godzina_odbioru
        );
      };
    return (
<div className="step__content">
<hr  className={linename}/>
      <div className="step__content__number">
        <div className="step__content__number__circle"></div>
        <div className="step__content__number__number">{number}</div>
      </div>
      <div className="step__content__title">
        {titleText}
      </div>
      <div className="step__content__description">
        {descriptionText} {" "}
        <a
          className="step__content__description__mail"
          href={
            "mailto:serwis@erpatech.pl?subject=Dane%20z%20formularza&body=" +
            getBody()
          }
        >
          {mail}
        </a>
      </div>
    </div>
      );
}
 
