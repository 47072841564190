import './Header.scss';
import '../../App.scss';

const Header = ({banner}) => {

  return (
    <div className="header">
      {banner}
    </div>
  );
};

export default Header;
